import React, { useState, useEffect, useRef, useContext } from "react";
import { View, Text, PanResponder, Platform, Image, TouchableOpacity, Linking } from "react-native";
import HTML from "react-native-render-html";
import * as WebBrowser from "expo-web-browser";
import { WebView } from "react-native-webview";
import { Video } from "expo-av";
import * as Animatable from "react-native-animatable";
import { Ionicons } from "@expo/vector-icons";

import PDFReader from "rn-pdf-reader-js";

import AuthContext from "../../context/AuthContext";
import AudioPlayer from "./AudioPlayer";
import UploadMission from "../itineraryComponents/UploadMission";
import TestMission from "../itineraryComponents/TestMission";
import * as DocumentPicker from "expo-document-picker";

let YouTube;

if (Platform.OS === "web") {
	YouTube = require("react-youtube").default;
}

const MediaViewerManager = ({ type, url = "", cover = "", height, width, minHeight = 300, maxHeight = 800, resizeMode = "cover", autoSize = true, useNativeControls = false, tracking = false, playbackCallback, videoProps, uploadData, uploadType }) => {
	let playbackInstance = useRef();
	const { dimensions } = useContext(AuthContext);

	const videoRef = useRef();

	const [containerWidth, setContainerWidth] = useState(dimensions.width >= 900 ? 900 : dimensions.width);
	const [aspectRatio, setAspectRatio] = useState(1);
	const [size, setSize] = useState({
		width: 0,
		height: 0,
	});
	const [uploadedDoc, setUploadedDoc] = useState({});
	const [embedded, setEmbedded] = useState("");
	const [userResponse, setUserResponse] = useState(null);

	const mockUploadContent = {
		content: {
			description: "",
			seconds: 40000,
		},
	};

	useEffect(() => {
		type === "TEXT" && Image.getSize(cover, (width, height) => setSize({ height, width }));
	}, [cover]);

	useEffect(() => {
		tracking && type === "VIDEO" && playbackInstance && videoProps && videoProps.positionMillis && playbackInstance.current.setPositionAsync(videoProps.positionMillis);
	}, [url]);

	useEffect(() => {
		if (type === "EMBEDDED") {
			let parsedUrl = new URL(url);
			const searchParams = Object.fromEntries(Array(...parsedUrl.searchParams.entries()));
			setEmbedded(searchParams.v);
		}
	}, [url]);

	const panResponder = useRef(
		PanResponder.create({
			onStartShouldSetPanResponder: (evt, gestureState) => true,
		})
	).current;

	const getMediaDimensions = (element) => {
		let ratio;

		if (Platform.OS === "web" && autoSize) {
			ratio = element.target.videoWidth / element.target.videoHeight;
			setSize({
				width: containerWidth,
				height: containerWidth / ratio,
			});
			setAspectRatio(ratio);
			const vid = document.getElementsByTagName("video")[0];
			vid.height = containerWidth / ratio;
			vid.setAttribute("playsinline", "");
			vid.setAttribute("webkit-playsinline", "");
		} else {
			ratio = element.naturalSize.width / element.naturalSize.height;
			setSize({
				height: containerWidth / ratio,
				width: containerWidth,
			});
		}
	};

	const uploadDocument = async () => {
		let result = await DocumentPicker.getDocumentAsync({ type: "application/*" });
		setUploadedDoc(result);
	};

	return (
		<View style={{ height, width }}>
			{url && type === "VIDEO" && (
				<View onLayout={(ev) => setContainerWidth(ev.nativeEvent.layout.width)} style={{ ...size, height: containerWidth / aspectRatio, width: "100%", overflow: "hidden", backgroundColor: "black" }}>
					<Video
						style={{ flex: 1 }}
						onReadyForDisplay={(ev) => getMediaDimensions(ev)}
						resizeMode={resizeMode}
						useNativeControls={useNativeControls}
						progressUpdateIntervalMillis={1000}
						onPlaybackStatusUpdate={playbackCallback}
						ref={playbackInstance}
						source={{ uri: url }}
						{...videoProps}
					/>
				</View>
			)}

			{url && type === "AUDIO" && <AudioPlayer source={url} cover={cover} millis={videoProps?.positionMillis || 0} {...videoProps} onPlaybackStatusUpdate={(ev) => console.log(ev)} />}

			{url && type === "DOCUMENT" && (
				<View {...panResponder.panHandlers} style={{ height: 400 }}>
					{Platform.OS !== "web" ? (
						<PDFReader
							style={{ flex: 1 }}
							withScroll
							renderType="DIRECT_URL"
							source={{
								uri: url,
							}}
						/>
					) : (
						<object data={url} type="application/pdf" width="100%" height={600}></object>
					)}
				</View>
			)}

			{/* {embedded &&
				type === "EMBEDDED" &&
				(Platform.OS !== "web" ? (
					<WebView
						source={`https://www.youtube.com/embed/${embedded}`}
						style={{ height: "auto", minHeight: 300, width: "100%" }}
						// onLoad={() => loadingCallback(false)}
						// onError={() => loadingCallback(false)}
					/>
				) : (
					<YouTube opts={{ width: "100%", playerVars: { modestbranding: "1" } }} videoId={embedded} onStateChange={(e) => console.log(e)} />
					// <iframe itemRef={videoRef} style={{ alignSelf: "center" }} width="100%" height="405" src={`https://www.youtube.com/embed/${embedded}?rel=0`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				))} */}

			{type === "UPLOAD" && uploadType.video ? (
				<View style={{ backgroundColor: "white", height: "100%", borderBottomLeftRadius: 15, borderBottomEndRadius: 15, flex: 1 }}>
					<UploadMission data={mockUploadContent} responseCallback={(response) => setUserResponse(response)} userResponse={userResponse} />
				</View>
			) : type === "UPLOAD" && !uploadType.video ? (
				<>
					<TouchableOpacity style={{ height: 40, width: 200, alignItems: "center", borderRadius: 7, backgroundColor: "#1d527f", justifyContent: "center", alignSelf: "center" }} onPress={() => uploadDocument()}>
						<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "white" }}>Subir documento</Text>
					</TouchableOpacity>
					{uploadedDoc.uri ? (
						<View {...panResponder.panHandlers} style={{ height: 400, marginTop: 10, marginBottom: 10 }}>
							{Platform.OS !== "web" ? (
								<PDFReader
									style={{ flex: 1 }}
									withScroll
									renderType="DIRECT_URL"
									source={{
										uri: uploadedDoc?.uri,
									}}
								/>
							) : (
								<object data={uploadedDoc?.uri} type="application/pdf" width="100%" height={400}></object>
							)}
						</View>
					) : (
						<View style={{ flex: 1, minHeight: 400, marginTop: 10, marginBottom: 10, alignItems: "center", justifyContent: "center" }}>
							<Text style={{ fontSize: 18, fontFamily: "DemiBold", color: "black" }}>Aun no has subido ningún documento</Text>
						</View>
					)}
				</>
			) : null}
		</View>
	);
};

export default MediaViewerManager;
