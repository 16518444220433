import React, { useState, useEffect, useRef, useContext } from "react";
import { View, Text, Platform, Image } from "react-native";
import HTML from "react-native-render-html";
import * as WebBrowser from "expo-web-browser";
import { WebView } from "react-native-webview";
import * as Animatable from "react-native-animatable";

// import ExampleComponent from "@upandgo/react-scorm-container";

import { Ionicons } from "@expo/vector-icons";

import AuthContext from "../context/AuthContext";
import MediaViewerManager from "./common/MediaViewerManager";
import ScormContainer from "./scormContainer";

const PostSmartContent = ({ id, mandatory, contentCallback, type, isPlaying, data, timeRemaining, loadingCallback, scormCallback, loadingNextContent, nextContentReady, readyCallback, secondsCallback, secondsToReady, lastWatched = 0 }) => {
	const { dimensions } = useContext(AuthContext);

	const shrink = { 0: { opacity: 1, height: 60 }, 1: { opacity: 0, height: 0 } };

	useEffect(() => {
		if (type === "VIDEO" || type === "AUDIO") {
			if (isPlaying) {
				if (secondsToReady > 0) {
					const interval = setInterval(() => {
						secondsCallback(secondsToReady - 1);
					}, 1000);
					return () => clearInterval(interval);
				}
				if (secondsToReady === 0) {
					readyCallback();
				}
			}
		}
		if (type !== "VIDEO" && type !== "AUDIO") {
			if (secondsToReady > 0) {
				const interval = setInterval(() => {
					secondsCallback(secondsToReady - 1);
				}, 1000);
				return () => clearInterval(interval);
			}
			if (secondsToReady === 0) {
				readyCallback();
			}
		}
	}, [secondsToReady]);

	return data?.contentType === "SCORM" || data?.contentType === "CODE" ? (
		[
			Platform.OS === "web" ? (
				data?.contentType === "SCORM" ? (
					<ScormContainer key={1} data={data?.fullData} loadCallback={() => null} scormCallback={(ev) => scormCallback(ev)} initRoute={data.code} />
				) : (
					<div dangerouslySetInnerHTML={{ __html: data.code }} />
				)
			) : (
				<WebView
					key={2}
					injectedJavaScript={`const meta = document.createElement('meta'); meta.setAttribute('content', 'width=width, initial-scale=0.7, maximum-scale=0.7, user-scalable=2.0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `}
					scalesPageToFit={false}
					source={{ uri: data.externalMedia }}
					style={{ height: "auto", width: dimensions.width * 1 }}
					onLoadEnd={() => {
						loadingCallback(false);
					}}
					onError={() => loadingCallback(false)}
				/>
			),
		]
	) : (
		<View
			key={2}
			style={{
				flex: 1,
				borderTopRightRadius: dimensions.width >= 1000 ? 15 : 0,
				borderBottomRightRadius: dimensions.width >= 1000 ? 15 : 0,
				// backgroundColor: "red"
			}}>
			<MediaViewerManager
				type={data?.contentType}
				url={data?.media}
				cover={data?.image}
				tracking={true}
				videoProps={{
					useNativeControls: !mandatory,
					positionMillis: lastWatched,
					onPlaybackStatusUpdate: contentCallback,
				}}
			/>

			{nextContentReady !== null && !nextContentReady && timeRemaining !== 0 && (
				<Animatable.View animation={!nextContentReady ? null : shrink} style={{ flexDirection: "row", paddingVertical: 10, paddingHorizontal: 15, alignItems: "center", marginTop: 10, marginHorizontal: 10, borderRadius: 9, backgroundColor: "gainsboro" }}>
					<Ionicons style={{ marginRight: 10 }} name="ios-information-circle-outline" size={20} color="black" />
					<View style={{ paddingRight: 20 }}>
						{data.contentType === "SCORM" ? <Text style={{ fontFamily: "Regular", fontSize: 13 }}>Siguiente contenido activo al finalizar todos los contenidos</Text> : <Text style={{ fontFamily: "Regular", fontSize: 13 }}>Siguiente contenido activo en: {timeRemaining}</Text>}
					</View>
				</Animatable.View>
			)}

			<View style={{ flex: 1, paddingHorizontal: 10, marginBottom: 20, maxWidth: 900 }}>
				{/* <TouchableOpacity style={{ flex: 1 }} onPress={() => this.playbackInstance.setPositionAsync(lastWatched)}><Text style={{ fontSize: 40, color: 'red' }}>PLAY</Text></TouchableOpacity> */}
				<Text style={{ fontSize: 30, marginVertical: 20, fontFamily: "DemiBold" }}>{data?.title}</Text>
				<HTML
					baseFontStyle={{ fontFamily: "Regular", fontSize: 18, color: "#303030" }}
					html={data?.content ? data?.content : "<p></p>"}
					imagesMaxWidth={dimensions.width}
					staticContentMaxWidth={dimensions.width}
					onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
				/>
			</View>
		</View>
	);
};

export default PostSmartContent;
