import React, { useState, useEffect, useContext, useRef } from "react";
import { View, Text, TouchableOpacity, ScrollView, StyleSheet, Platform, FlatList, Image, Dimensions } from "react-native";
import PostDestacado from "./CardFeaturedPost";
import CardPost from "./CardPost";
import { useNavigation } from "@react-navigation/native";
import LottieView from "lottie-react-native";
import * as Animatable from "react-native-animatable";
import { Line, Element, Block, Paragraph, MagicBox, LoadAnimation } from "../../components/placeholders/MagicBox";

import * as Analytics from "expo-firebase-analytics";

import AuthContext from "../../context/AuthContext";

import { firestore, storage } from "firebase";

import * as Localization from "expo-localization";
import i18n from "i18n-js";
import translations from "../../locale/translations";
import { useSafeArea } from "react-native-safe-area-context";

import Button from "../common/Button";
import { Ionicons } from "@expo/vector-icons";

i18n.translations = translations;
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
i18n.fallbacks = true;

let unsubscribeContents;
let unsubscribeFeaturedContents;

const CommunityFeed = ({ id, today, route, navigation }) => {
	const { navigate } = useNavigation();
	const { userData, customization, dimensions } = useContext(AuthContext);
	const insets = useSafeArea();
	const catRef = useRef();
	const [limit, setLimit] = useState(6);
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [dataFeatured, setDataFeatured] = useState([]);
	const [isMoreData, setIsMoreData] = useState(true);
	const [totalScrollCat, setTotalScrollCat] = useState(0);
	const [currentScrollCat, setCurrentScrollCat] = useState(0);
	const error = false;

	useEffect(() => {
		setLoading(true);
		firestore()
			.collection("tenants")
			.doc(userData.tenants[0].id)
			.collection("categories")
			.where("usergroups", "array-contains-any", userData.usergroups)
			// .orderBy("order", "asc")
			.onSnapshot((res) => {
				Promise.all(
					res.docs.map(async (cat) => {
						const img = cat.data().image;
						const url = img
							? await storage()
									.ref(img)
									.getDownloadURL()
									.then((res) => res)
									.catch((err) => "")
							: "";
						return { id: cat.id, ...cat.data(), image: url };
					})
				).then((res) => {
					setTotalScrollCat(res.length * 220);
					setCategories(res);
					getFeaturedContents();
					getContents();
				});
			});

		return () => {
			unsubscribeContents();
			unsubscribeFeaturedContents();
		};
	}, []);

	useEffect(() => {
		getContents();
	}, [limit]);

	const getContents = () => {
		var d = new Date();
		d.setDate(d.getDate() - 7);

		unsubscribeContents = firestore()
			.collection("tenants")
			.doc(userData.tenants[0].id)
			.collection("contents")
			.where("usergroups", "array-contains-any", userData.usergroups)
			.where("featured", "==", false)
			.where("published", "==", true)
			// .where("publishDate", ">", d)
			.orderBy("publishDate", "desc")
			.limit(6)
			// .limit(limit)
			.onSnapshot((querySnapshot) => {
				Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const img = doc.data().image;
						const url = img
							? await storage()
									.ref(img)
									.getDownloadURL()
									.then((res) => res)
									.catch((err) => "")
							: "";
						return { ...doc.data(), id: doc.id, image: url };
					})
				).then((res) => {
					setData(res);
					setLoading(false);
					limit > res.length && setIsMoreData(false);
				});
			});
	};

	const getFeaturedContents = () => {
		unsubscribeFeaturedContents = firestore()
			.collection("tenants")
			.doc(userData.tenants[0].id)
			.collection("contents")
			.where("usergroups", "array-contains-any", userData.usergroups)
			.where("featured", "==", true)
			.orderBy("publishDate", "desc")
			.limit(8)
			.onSnapshot((querySnapshot) => {
				Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const img = doc.data().image;
						const url = img
							? await storage()
									.ref(img)
									.getDownloadURL()
									.then((res) => res)
									.catch((err) => "")
							: "";
						return { ...doc.data(), id: doc.id, image: url };
					})
				).then((res) => {
					setDataFeatured(res);
				});
			});
	};

	if (error) {
		console.log(error);
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center", padding: 10 }}>
				{Platform.OS !== "web" && (
					<LottieView
						autoPlay
						style={{
							width: 200,
							height: 200,
							marginBottom: 30,
						}}
						source={require("../../assets/animations/error.json")}
					/>
				)}
				<Text style={{ fontFamily: "DemiBold", fontSize: 16 }}>Ops Ha ocurrido un Error, intentelo más tarde</Text>
			</View>
		);
	}

	return (
		<FlatList
			data={data}
			showsVerticalScrollIndicator={false}
			extraData={data}
			numColumns={Platform.OS === "web" ? (dimensions.width > 760 ? 2 : 1) : 1}
			key={dimensions.width > 760}
			ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
			ListHeaderComponent={
				<View>
					{/* <Text style={{ textAlign: Platform.OS === "web" ? "center" : null, marginHorizontal: 10, marginTop: 20, marginBottom: 10, fontFamily: "Bold", fontSize: 34 }}>{i18n.t("categories")}</Text> */}
					<View style={{ width: "100%", alignItems: "center" }}>
						<View style={{ width: "100%", maxWidth: 1000 }}>
							{Platform.OS === "web" && dimensions.width > 760 && categories.length > 4 && (
								<Button
									onPress={() => {
										if (currentScrollCat >= 0) {
											const next = currentScrollCat - 220 * 4;
											const e = next < 0 ? 0 : next;
											setCurrentScrollCat(e);
											catRef.current.scrollTo({ x: e, animated: true });
										}
									}}
									style={{ position: "absolute", top: "30%", left: -25, zIndex: 10 }}
									width={50}
									height={50}
									round={25}
									color={customization.mainColor}>
									<Ionicons name='caret-back' color='white' size={24} />
								</Button>
							)}

							{Platform.OS === "web" && dimensions.width > 760 && categories.length > 4 && (
								<Button
									onPress={() => {
										if (currentScrollCat <= totalScrollCat) {
											const next = currentScrollCat + 220 * 4;
											const e = next >= totalScrollCat ? totalScrollCat : next;
											setCurrentScrollCat(e);
											catRef.current.scrollTo({ x: e, animated: true });
										}
									}}
									style={{ position: "absolute", top: "30%", right: -25, zIndex: 10 }}
									width={50}
									height={50}
									round={25}
									color={customization.mainColor}>
									<Ionicons name='caret-forward' color='white' size={24} />
								</Button>
							)}

							<ScrollView
								ref={catRef}
								decelerationRate='fast'
								snapToInterval={250}
								snapToAlignment={"start"}
								centerContent={true}
								showsHorizontalScrollIndicator={false}
								contentContainerStyle={{ justifyContent: "center", paddingHorizontal: 10, paddingVertical: 20, paddingBottom: 20, flexGrow: 1 }}
								horizontal>
								{loading
									? Array.from(Array(3).keys()).map((el, i) => (
											<Block key={i} backgroundColor='transparent' width={220} margin={10}>
												<Element height={150} />
												<Line margin={10} />
											</Block>
									  ))
									: categories.map((category, i, arr) => {
											return (
												<Animatable.View animation='fadeInUp' key={i}>
													<TouchableOpacity onPress={() => navigation.navigate("Category", { id: category.id })} style={{ width: Platform.OS === "web" ? 250 : 220 }}>
														<View style={(styles.shadow, { width: Platform.OS === "web" ? 240 : 200, height: Platform.OS === "web" ? 300 : 120 })}>
															<Image
																source={{ uri: category.image || "https://i1.wp.com/autohub.de/wp-content/uploads/2019/08/placeholder.png?fit=1200%2C800&ssl=1" }}
																key={category.image}
																style={{
																	borderRadius: 10,
																	paddingLeft: 15,
																	paddingBottom: 10,
																	paddingRight: 10,
																	justifyContent: "flex-end",
																	position: "relative",
																	width: Platform.OS === "web" ? 240 : 200,
																	height: Platform.OS === "web" ? 300 : 120,
																}}
															/>
															<View style={{ backgroundColor: customization.mainColor, borderRadius: 7, padding: 10, margin: 5, position: "absolute", top: 0, left: 0, right: 0 }}>
																<Text 
																// truncate='true' numberOfLines={1}
																style={{ textAlign:"center", color:"white", fontSize: Platform.OS === "web" ? 16 : 16, fontFamily: "Bold" }}>
																	{category.name}
																</Text>
																{/* <Text style={{ fontFamily: "DemiBold", fontSize: 12, color: "gray" }}>{category.contents ? category.contents.length : 0} {i18n.t('contents')}</Text> */}
															</View>
														</View>
													</TouchableOpacity>
												</Animatable.View>
											);
									  })}
							</ScrollView>
						</View>
					</View>
					{dataFeatured.length > 0 && <Text style={{ textAlign: Platform.OS === "web" ? "center" : null, margin: 10, fontFamily: "Bold", color: customization.mainColor, fontSize: 34 }}>{i18n.t("featured")}</Text>}
					{dataFeatured.length > 0 && (
						<ScrollView
							decelerationRate={0}
							snapToInterval={320}
							snapToAlignment={"start"}
							disableIntervalMomentum={true}
							centerContent={true}
							showsHorizontalScrollIndicator={false}
							contentContainerStyle={{ paddingHorizontal: 10, paddingVertical: 20, flexGrow: 1, justifyContent: "center" }}
							horizontal>
							{loading ? (
								<Block card width='100%' cardStyles={{ maxWidth: 960 }} margin={10}>
									<Element height={250} />
									<Paragraph lines={3} margin={20} />
								</Block>
							) : (
								dataFeatured.map((post, i, arr) => {
									return (
										<PostDestacado
											key={i}
											onlyCard={dataFeatured.length === 1}
											last={i === dataFeatured.length - 1}
											position={i}
											title={post.title}
											id={post.id}
											publishDate={post.publishDate.toDate()}
											categories={[]}
											image={post.image}
											likes={post.likes}
											comments={post.comments}
											maxWidth={dataFeatured.length === 1 ? 900 : 430}
											pressCallback={() => {
												navigation.navigate("Post", { id: post.id });
												Analytics.logEvent("postView", {
													contentType: "post",
													itemId: post.id,
													method: "app",
												});
											}}
										/>
									);
								})
							)}
						</ScrollView>
					)}
					<Text style={{ textAlign: Platform.OS === "web" ? "center" : null, marginHorizontal: 10, marginBottom: 30, marginTop: 10, fontFamily: "Bold",color: customization.mainColor, fontSize: 34 }}>{i18n.t("news")}</Text>
				</View>
			}
			// ListFooterComponent={
			// <View style={{ padding: 15, alignItems: "center" }}>
			// 	{isMoreData ? (
			// 		<Button
			// 			style={{ maxWidth: 200 }}
			// 			round={25}
			// 			color={customization.mainColor}
			// 			label='Más Contenidos'
			// 			onPress={() => {
			// 				setLimit(limit + 5);
			// 			}}
			// 		/>
			// 	) : null}
			// </View>
			// }
			contentContainerStyle={{ paddingBottom: 70 + insets.bottom }}
			columnWrapperStyle={Platform.OS === "web" && dimensions.width > 760 && { alignSelf: "center", width: "100%", maxWidth: 900 }}
			renderItem={({ item, index }) => (
				<View style={{ flex: 1 }}>
					{loading ? (
						<Block direction={"row"} card gap={10} margin={10} width='100%' padding={{ right: 10 }} align={"center"}>
							<Element height={120} width='35%' />
							<Paragraph lines={3} />
						</Block>
					) : (
						<CardPost
							key={index}
							position={index}
							title={item.title}
							idpost={item.id}
							user={item.user}
							publishDate={item.publishDate.toDate()}
							categories={[]}
							image={item.image}
							comments={item.comments}
							likes={item.likes}
							navigate={navigate}
							pressCallback={() => {
								navigation.navigate("Post", { id: item.id });
								Analytics.logEvent("postView", {
									contentType: "post",
									itemId: item.id,
									method: "app",
								});
							}}
						/>
					)}
				</View>
			)}
			keyExtractor={(item, index) => index.toString()}
			ListEmptyComponent={() => (
				<View style={{ marginTop: 35, marginBottom: 15, flex: 1, justifyContent: "center", alignItems: "center" }}>
					<Text style={{ color: "gray", fontFamily: "DemiBold", fontSize: 16 }}>No hay Contenidos</Text>
				</View>
			)}
		/>
	);
};

export default CommunityFeed;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},

	buttonTop: {
		backgroundColor: "white",
		borderColor: "#679BA2",
		width: "50%",
		height: 55,
		alignItems: "center",
		justifyContent: "center",
	},
	buttonTopText: {
		fontFamily: "DemiBold",
		fontSize: 18,
	},

	shadow: {
		shadowColor: "#000000",
		shadowOffset: {
			width: 0,
			height: 10,
		},
		shadowOpacity: 0.1,
		shadowRadius: 10.0,
		backgroundColor: "white",

		elevation: 24,
	},
	drawer: {
		shadowColor: "#000000",
		shadowOpacity: 0.8,
		shadowRadius: 3,
		elevation: 2,
	},
	main: { paddingLeft: 3 },
});
