import React, { useState, useEffect, useRef, useContext } from "react";
import { View, ScrollView, StyleSheet, Alert, Platform, ActivityIndicator } from "react-native";

import Header from "../../components/AppHeader";
import PostSmartContent from "../../components/PostSmartContent";

import * as Animatable from "react-native-animatable";

import HeaderWeb from "../../components/HeaderWeb";
import _ from "lodash";

import { useSafeArea } from "react-native-safe-area-context";
import CourseCollapsableItems from "../../components/smartComponents/CourseCollapsableItems";

import PointsContext from "../../context/PointsContext";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";
import CourseItemsWeb from "../../components/smartComponents/CourseItemsWeb";
import Button from "../../components/common/Button";

const PostSmart = ({ id, route, navigation }) => {
	const { userData, customization, dimensions } = useContext(AuthContext);
	const { pointsContext } = useContext(PointsContext);

	const advanceID = route.params.advanceID;

	const [activecontent, setActiveContent] = useState(route.params.id);

	// console.log("ID DE ANVEGADOR ", route.params.id)

	const [data, setData] = useState({});
	const [originalData, setOriginalData] = useState({});
	const currentIndexRef = useRef()
	const dataRef = useRef();
	const contentRef = useRef();

	const [loadIndicator, setLoadIndicator] = useState(false);

	// MARCADORES DE QUE CONTENIDO Y SECCION ES
	const [arrayContents, setArrayContents] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [currentIndexGlobal, setCurrentIndexGlobal] = useState(0);
	const [sectionIndex, setSectionIndex] = useState(0);
	const [nextContentReady, setNextContentReady] = useState(null);
	const [secondsToReady, setSecondsToReady] = useState(0);
	const [loading, setLoading] = useState(true);

	const [toogled, setToggled] = useState(null);
	const [currentContentTime, setCurrentContentTime] = useState(0);
	const [completed, setCompleted] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false);

	const insets = useSafeArea();

	useEffect(() => {
		// ACTUALIZA LOS ESTADOS CADA VEZ QUE CAMBIE EL CONTENIDO ACTIVO
		// console.log("CACA", activecontent)
		data.advance && setupData(data);
	}, [activecontent]);

	// console.log("GLOBALINDEX", currentIndexGlobal)

	const secondsToTime = (time) => {
		// Hours, minutes and seconds
		var hrs = ~~(time / 3600);
		var mins = ~~((time % 3600) / 60);
		var secs = ~~time % 60;

		// Output like "1:01" or "4:03:59" or "123:03:59"
		var ret = "";

		if (hrs > 0) {
			ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
		}

		ret += "" + mins + ":" + (secs < 10 ? "0" : "");
		ret += "" + secs;
		return ret;
	};

	const setupData = (data) => {
		// CONTENIDO DEL AVANCE
		const advance = data.advance;
		// ARRAY DE TODOS LOS CONTENIDOS DEL AVANCE
		const arr = advance.reduce((newarr, section) => [...newarr, ...section.contents], []);
		// CONTENIDO VISUALIZANDOSE ACTUALMENTE
		const arrIndex = arr.findIndex((element) => element.id === activecontent);
		// TOTAL DE CONTENIDOS COMPLETADOS
		const totalCompleted = arr.reduce((total, post) => {
			const value = post.status === "COMPLETED" ? 1 : 0;
			return total + value;
		}, 0);
		// INDICE DE LA SECCION DEL CONTENIDO ACTIVO
		const lastSection = advance.findIndex((element) => {
			const index = element.contents.findIndex((c) => c.id === activecontent);
			return index >= 0;
		});
		// INDICE DEL CONTENIDO ACTIVO DENTRO DE LA SECCION
		const lastContent = advance[lastSection]?.contents.findIndex((element) => element.id === activecontent);
		// ARRAY DE TODOS LOS CONTENIDO
		setArrayContents(arr);
		// INDICE DEL CONTENIDO DENTRO DEL ARRAY DE CONTENIDOS
		setCurrentIndexGlobal(arrIndex === -1 ? 0 : arrIndex);
		// INDICE DE LA SECCION DEL CONTENIDO ACTUAL
		setSectionIndex(lastSection);
		// INDICE DEL CONTENIDO DENTRO DE SU SECCIÓN
		setCurrentIndex(lastContent);
		currentIndexRef.current = lastContent
		//NUMERO TOTAL DE CONTENIDOS COMPLETADOS
		setCompleted(totalCompleted);
		// CHECK IF MANDATORY AND IF SO SET TIMER TO NEXT CONTENT
		if (arr[arrIndex]?.status === "COMPLETED") {
			setCurrentContentTime(0);
			setNextContentReady(true);
			return true;
		} else {
			if (!arr[arrIndex]?.mandatory) {
				setNextContentReady(true);
				setCurrentContentTime(arr[arrIndex]?.lastWatched ? arr[arrIndex]?.lastWatched : 0);
			} else {
				if (arr[arrIndex].contentType === "VIDEO" || arr[arrIndex].contentType === "AUDIO") {
					setNextContentReady(false);
					setCurrentContentTime(arr[arrIndex].lastWatched ? arr[arrIndex].lastWatched : 0);
					arr[arrIndex].lastWatched && arr[arrIndex].contentLength - arr[arrIndex].lastWatched / 1000 <= 0 ? setNextContentReady(true) : setNextContentReady(false);
					setSecondsToReady(arr[arrIndex].lastWatched ? arr[arrIndex].contentLength - arr[arrIndex].lastWatched / 1000 : 0);
				} else if (arr[arrIndex].contentType === "SCORM") {
					setNextContentReady(false);
					arr[arrIndex].fullData?.core?.lesson_status === "completed" ? setNextContentReady(true) : setNextContentReady(false);
				} else {
					setNextContentReady(false);
					setSecondsToReady(arr[arrIndex].contentLength);
				}
			}
		}
	};

	useEffect(() => {
		setLoading(true);
		var loadData = false;
		const unsubscribe = firestore()
			.collection("users")
			.doc(userData.id)
			.collection("courseavs")
			.doc(advanceID)
			.onSnapshot(async (querySnapshot) => {
				// const img = querySnapshot.data().image
				// const url = img ? await storage().ref(img).getDownloadURL().then(res=>res).catch(err => "") : null
				Promise.all(
					querySnapshot.data().advance?.map((section) => {
						return Promise.all(
							section.contents.map(async (content) => {
								const img = content?.image;
								const url = img
									? await storage()
											.ref(img)
											.getDownloadURL()
											.then((res) => res)
											.catch((err) => "")
									: null;
								const media = content.media;
								const urlmedia = media
									? await storage()
											.ref(media)
											.getDownloadURL()
											.then((res) => res)
											.catch((err) => "")
									: null;
								return {
									...content,
									image: url,
									media: urlmedia,
								};
							})
						).then((res) => {
							return {
								...section,
								contents: res,
							};
						});
					})
				).then((res) => {
					const newData = { ...querySnapshot.data(), initDate: querySnapshot.data().initDate ? querySnapshot.data().initDate.toDate() : false, id: querySnapshot.id, advance: res };
					setOriginalData(querySnapshot.data().advance);
					dataRef.current = querySnapshot.data().advance;
					setData(newData);
					!loadData && setupData(newData);
					loadData = true;
					setLoading(false);
				});
			});

		return () => unsubscribe();
	}, [advanceID]);

	const isNextContentReady = (i) => {
		// console.log(i);
		const arr = dataRef.current.reduce((acc, cur) => [...acc, ...cur.contents], []);
		// console.log(arr);

		const arrIndex = arr.findIndex((element) => element.id === i);

		// console.log(arrIndex, arr[arrIndex]?.status, arr[arrIndex]?.mandatory, arr[arrIndex].contentType);

		if (arr[arrIndex]?.status === "COMPLETED") {
			return true;
		} else {
			if (!arr[arrIndex]?.mandatory) {
				return true;
			} else {
				if (arr[arrIndex].contentType === "VIDEO" || arr[arrIndex].contentType === "AUDIO") {
					return false;
				} else if (arr[arrIndex].contentType === "SCORM") {
					console.log("ES ESCORMM  ", arr[arrIndex]?.fullData?.core?.lesson_status);
					if (arr[arrIndex]?.fullData?.core?.lesson_status === "completed") {
						return true;
					} else {
						return false;
					}
				} else {
					if(secondsToReady === 0){
						return true
					} else {
						return false;
					}
				}
			}
		}
	};

	const contentCallback = (playbackStatus) => {
		if (!playbackStatus.isLoaded) {
			// Update your UI for the unloaded state
			if (playbackStatus.error) {
				console.log(`Encountered a fatal error during playback: ${playbackStatus.error}`);
				// Send Expo team the error on Slack or the forums so we can help you debug!
				Alert.alert("Ha ocurrido un error cargando el contenido. Por favor inténtelo más tarde");
			}
		} else {
			// Update your UI for the loaded state
			setSecondsToReady(Math.round((playbackStatus.durationMillis - playbackStatus.positionMillis) / 1000));
			if (playbackStatus.isPlaying) {
				// CALCULA LOS SEGUNDOS DE VIDEO RESTANTES
				setSecondsToReady(Math.round((playbackStatus.durationMillis - playbackStatus.positionMillis) / 1000));
				!isPlaying && setIsPlaying(true);
				// ACTUALIZA EL AVANCE CON EL ULTIMO SEGUNDO VISUALIZADO
				if (Math.round(playbackStatus.positionMillis / 1000) % 10 == 0 && Math.round(playbackStatus.positionMillis / 1000) !== 0) {
					if (arrayContents[currentIndexGlobal].lastWatched < playbackStatus.positionMillis) {
						const newarr = originalData.map((section) => {
							return {
								...section,
								contents: section.contents.map((content) => {
									if (content.id === activecontent) {
										return { ...content, lastWatched: playbackStatus.positionMillis };
									} else {
										return content;
									}
								}),
							};
						});
						// setLoadIndicator(true)
						!loadIndicator &&
							firestore()
								.collection("users")
								.doc(userData.id)
								.collection("courseavs")
								.doc(advanceID)
								.update({ advance: [...newarr] })
								.catch((err) => console.log(err));
					}
				}
			} else {
				// Update your UI for the paused state
				setIsPlaying(false);
			}
			if (playbackStatus.isBuffering) {
				setIsPlaying(false);
				// Update your UI for the buffering state
			}
			if (playbackStatus.didJustFinish && !playbackStatus.isLooping) {
				setIsPlaying(false);
				// ACTIVA EL SIGUIENTE CONTENIDO SI ES OBLIGATORIO
				setNextContentReady(true);
			}
		}
	};

	const _previousStep = async () => {
		arrayContents[currentIndexGlobal - 1].contentType === "EXAM"
			? navigation.replace("Exam", {
					id: arrayContents[currentIndexGlobal - 1].id,
					advanceID: data.id,
			  })
			: contentRef.current.fadeOutRight(500).then(() => {
					contentRef.current.fadeInLeft(500);
					setActiveContent(arrayContents[currentIndexGlobal - 1].id);
			  });
	};

	const onSectionComplete = (idx) => {
		const sectionPoints = parseInt(arrayContents[idx]?.sectionPoints);
		const { achievements } = customization;
		const { givePoints } = pointsContext;
		const completedSectionPoints = {
			points: sectionPoints || "0",
			showPoints: true,
			message: "Sección completada",
		};
		// givePoints(completedSectionPoints, achievements);
	};

	const onCourseComplete = () => {
		const totalCoursePoints = parseInt(data.coursePoints);
		const { achievements } = customization;
		const { givePoints } = pointsContext;
		const completedCoursePoints = {
			points: totalCoursePoints,
			showPoints: true,
			message: "Curso completado",
		};
		// givePoints(completedCoursePoints, achievements);
	};

	const _nextStep = async () => {
		arrayContents[currentIndexGlobal + 1].contentType === "EXAM"
			? navigation.replace("Exam", {
					id: arrayContents[currentIndexGlobal + 1].id,
					advanceID,
			  })
			: contentRef.current.fadeOutLeft(200).then(() => {
					setActiveContent(arrayContents[currentIndexGlobal + 1].id);
					// setupData(data)
					navigation.setParams({ id: arrayContents[currentIndexGlobal + 1].id });
					contentRef.current.fadeInRight(200);
			  });
	};

	const _jumpToContent = async (id) => {
		if (id === activecontent) {
			return;
		}

		const indexToJump = arrayContents.findIndex((el) => el.id === id);
		setLoadIndicator(true);
		arrayContents[indexToJump].contentType === "EXAM"
			? navigation.replace("Exam", {
					id: arrayContents[indexToJump].id,
					advanceID: data.id,
			  })
			: contentRef.current.fadeOutLeft(200).then(() => {
					setActiveContent(arrayContents[indexToJump].id);
					// setupData(data)
					navigation.setParams({ id: arrayContents[indexToJump].id });
					setLoadIndicator(false);
					contentRef.current.fadeInRight(200);
			  });
	};

	const prepareNext = (advance) => {
		var newarr = advance.map((section, i) => {
			if (i === sectionIndex) {
				return {
					...section,
					status: advance[sectionIndex].contents.length - 1 === currentIndex ? "COMPLETED" : "INITIATED",
					contents: section.contents.map((content, i) => {
						if (i === currentIndex) {
							return {
								...content,
								status: "COMPLETED",
							};
						}
						if (i === currentIndex + 1) {
							return {
								...content,
								status: "INITIATED",
							};
						} else {
							return content;
						}
					}),
				};
			} else {
				if (i === sectionIndex + 1 && advance[sectionIndex].contents.length - 1 === currentIndex) {
					return {
						...section,
						status: "INITIATED",
						contents: section.contents.map((content, i) => {
							if (i === 0) {
								return {
									...content,
									status: "INITIATED",
								};
							} else {
								return {
									...content,
								};
							}
						}),
					};
				} else {
					return {
						...section,
					};
				}
			}
		});

		return newarr;
	};

	const _completeStep = () => {
		setLoadIndicator(true);
		const arr = prepareNext(originalData);
		onSectionComplete(sectionIndex);
		firestore()
			.collection("users")
			.doc(userData.id)
			.collection("courseavs")
			.doc(advanceID)
			.update({ advance: arr })
			.then((res) => {
				setNextContentReady(null);
				setLoadIndicator(false);
				arrayContents[currentIndexGlobal + 1].contentType === "EXAM"
					? // SI ES UN EXAMEN NAVEGA AL EXAMEN
					  navigation.replace("Exam", {
							id: arrayContents[currentIndexGlobal + 1].id,
							advanceID,
					  })
					: // HACE LA ANIMACIÓN DE PASO A LA SIGUIENTE PANTALLA Y PONE EL SIGUIENTE CONTENIDO ACTIVO
					  contentRef.current.fadeOutLeft(200).then(() => {
							// setupData(data)
							setActiveContent(arrayContents[currentIndexGlobal + 1].id);
							navigation.setParams({ id: arrayContents[currentIndexGlobal + 1].id });
							contentRef.current.fadeInRight(200);
					  });
			})
			.catch((err) => {
				console.log(err);
				Alert.alert("Ha ocurrido un error capturando tu avance en el curso, por favor, inténtalo más tarde.");
				setLoadIndicator(false);
			});
	};

	const completeCourse = async () => {
		onSectionComplete(sectionIndex);
		setTimeout(() => {
			onCourseComplete();
		}, 2000);
		navigation.replace("CompleteCourse", {
			id: advanceID,
		});
	};

	const updateScormData = async (ev, p, i) => {
		const arr = dataRef.current;
		const d = arr[sectionIndex].contents[currentIndexRef.current];

		// console.log(arr);
		// console.log(ev.fullData?.core?.lesson_status === "completed")
		
		// if (ev.key === "cmi.suspend_data" && ev.fullData?.core?.lesson_status !== "completed") {
		// 	console.log("MASMDAMSDAMD AF SDF");
		// 	// console.log(ev.fullData?.core?.score?.raw, d.fullData?.core?.score?.raw);
		// 	// console.log(ev);
		// 	// console.log("QUE CONTENIDO? ", arr[sectionIndex].contents[currentIndex]);

		// 	arr[sectionIndex].contents[currentIndex] = { ...d, fullData: ev.fullData };

		// 	// console.log(arr);
		// 	firestore()
		// 		.collection("users")
		// 		.doc(userData.id)
		// 		.collection("courseavs")
		// 		.doc(advanceID)
		// 		.update({ advance: [...arr] })
		// 		.then((res) => {
		// 			console.log("EDITADO");
		// 			dataRef.current = [...arr];
		// 			setOriginalData([...arr]);
		// 		})
		// 		.catch((err) => {
		// 			console.log(err);
		// 		});
		// } else 
		if (ev.key === "cmi.suspend_data") {

			console.log(ev.key, "  ", ev.value, "current section ", sectionIndex, "  currentGame ", currentIndexRef.current, ev.fullData.suspend_data)

			arr[sectionIndex].contents[currentIndexRef.current] = { ...d, fullData: ev.fullData };

			// console.log(arr);
			firestore()
				.collection("users")
				.doc(userData.id)
				.collection("courseavs")
				.doc(advanceID)
				.update({ advance: [...arr] })
				.then((res) => {
					console.log("EDITADO");
					dataRef.current = [...arr];
					setOriginalData([...arr]);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	navigation.setOptions({
		...(data.name && { title: "Curso - " + data.name + " - " + arrayContents[currentIndexGlobal]?.name }),
		header: ({ scene, previous, navigation }) => {
			return Platform.OS === "web" ? (
				<HeaderWeb navigation={navigation} smartContents={true} toogleSmartCallback={() => setToggled(!toogled)} />
			) : (
				<Header logo={previous ? false : true} backCallback={() => navigation.replace("CourseActive", { id: advanceID })} backButton={true} smartContents={true} toogleSmartCallback={() => setToggled(!toogled)} />
			);
		},
	});
	const setHeader = () => {};

	if (loading)
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator size="large" />
			</View>
		);

	return (
		<View style={{ flex: 1, padding: dimensions.width >= 1000 ? 10 : 0 }}>
			{/* {Platform.OS !== "web" && <Header logo={false} smartContents={true} toogleSmartCallback={() => setToggled(!toogled)} backButton={true} notificationButton={false} searchButton={false} isTransparent={false} />} */}

			{dimensions.width <= 1000 && (
				<CourseCollapsableItems
					items={data.advance && data.advance.map((e, i) => ({ ...e, id: i }))}
					navigation={navigation}
					toogled={toogled}
					itemCallback={(ev) => {
						_jumpToContent(ev);
					}}
					lastCompleted={completed}
					activecontent={activecontent}
					arrayContents={arrayContents}
				/>
			)}

			<View style={[dimensions.width >= 1000 ? styles.shadow : null, { height: "92%", flexDirection: "row", alignSelf: "center", maxWidth: 1200, width: "100%", overflow: "hidden", borderRadius: dimensions.width >= 1000 ? 15 : 0 }]}>
				{dimensions.width >= 1000 && (
					<CourseItemsWeb
						items={data.advance && data.advance.map((e, i) => ({ ...e, id: i }))}
						navigation={navigation}
						toogled={toogled}
						itemCallback={(ev) => {
							_jumpToContent(ev);
						}}
						lastCompleted={completed}
						activecontent={activecontent}
						arrayContents={arrayContents}
					/>
				)}

				<View style={{ height: "100%", flex: 1 }}>
					<ScrollView alwaysBounceVertical={false} showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, paddingBottom: insets.bottom }}>
						<Animatable.View ref={contentRef} style={{ flex: 1, borderRadius: 15 }}>
							<PostSmartContent
								data={arrayContents[currentIndexGlobal]}
								id={activecontent}
								loadingCallback={() => {}}
								type={arrayContents[currentIndexGlobal]?.contentType}
								lastWatched={currentContentTime}
								loadingNextContent={loading}
								secondsToReady={secondsToReady}
								isPlaying={isPlaying}
								nextContentReady={nextContentReady}
								scormCallback={(ev) => updateScormData(ev, originalData, currentIndex)}
								contentCallback={(ev) => contentCallback(ev)}
								timeRemaining={secondsToTime(secondsToReady)}
								readyCallback={() => setNextContentReady(true)}
								secondsCallback={(ev) => setSecondsToReady(ev)}
							/>
						</Animatable.View>
					</ScrollView>
				</View>
			</View>

			<View
				style={{
					width: "100%",
					maxWidth: 1200,
					height: "10%",
					alignItems: "center",
					alignSelf: "center",
					// paddingBottom: Platform.OS === "android" ? 10 : Platform.OS === "web" ? 10 : insets.bottom - 10,
					justifyContent: "space-between",
					flexDirection: "row",
					zIndex: 30,
				}}>
				{currentIndexGlobal !== 0 && data.state !== "COMPLETED" && <Button onPress={() => _previousStep()} width={150} label="Anterior" />}

				{arrayContents.length - 2 >= currentIndexGlobal && data.state !== "COMPLETED" && (
					<Button
						loading={loadIndicator}
						disabled={!isNextContentReady(activecontent)}
						width={150}
						style={{ position: "absolute", right: 0, zIndex: 30 }}
						onPress={() => (arrayContents[currentIndexGlobal].status === "COMPLETED" ? _nextStep() : _completeStep())}
						// label={!nextContentReady ? "Siguiente" : arrayContents[currentIndexGlobal + 1].contentType === "EXAM" ? "Evaluación" : "Siguiente"}
						label={!isNextContentReady(activecontent) ? "Siguiente" : arrayContents[currentIndexGlobal + 1].contentType === "EXAM" ? "Evaluación" : "Siguiente"}
					/>
				)}

				{arrayContents.length === currentIndexGlobal + 1 && data.state !== "COMPLETED" && <Button disabled={!nextContentReady} onPress={() => completeCourse()} width={150} label="Finalizar" />}
			</View>
		</View>
	);
};

PostSmart.navigationOptions = {
	tabBarVisible: false,
};

export default PostSmart;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "rgba(0, 0, 0, 1)",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.4,
		shadowRadius: 15,
		elevation: 10,
	},
});
